<template>
   <div class="obi-header">
      <div class="obi-header--top">
         <RouterLink :to="{ name: 'dashboard-index' }" class="obi-brand">
            <ObiLogo />
         </RouterLink>
         <div class="obi-header--actions d-flex align-items-center">
            <RouterLink :to="{ name: 'account-settings' }">
               <ObiButton class="d-flex active justify-content-center align-items-center">
                  <ObiImage :src="require('@/assets/images/default-user-avatar.png')" />
                  <ObiText class="ms-2" v-html="`${authUser.first_name} ${authUser.last_name}`"></ObiText>
               </ObiButton>
            </RouterLink>
         </div>
      </div>
      <ModalLocationCreate ref="modalLocationCreate" @success="changeTeamToNew" />
   </div>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';

import ErrorHandler from '@/libs/error-handler';

import AuthApi from '@/api/AuthApi';
import IconNotification from '@/components/Icons/general/IconNotification';
import ModalLocationCreate from '@/views/pages/Location/_Modals/Create';

export default {
   name: 'ObiHeader',
   components: {
      ModalLocationCreate,
   },
   props: {},
   data() {
      return {
         IconNotification,
         isTeamChanging: false,
      };
   },
   computed: {
      ...mapGetters('auth', ['authUser', '']),
      teams() {
         return get(this, 'authUser.teams', {});
      },
      currentTeam() {
         return get(this, 'authUser.current_team', {});
      },
      notifications() {
         const notifications = get(this, 'authUser.notifications', []);
         const readNotifications = get(this, 'authUser.readNotifications', []);
         return notifications.concat(readNotifications);
      },
      unread() {
         return get(this, 'authUser.notifications.length');
      },
   },
   methods: {
      async changeTeam(team) {
         this.isTeamChanging = true;
         try {
            await AuthApi.changeTeam(team.id);
            await this.$router.push({ name: 'dashboard-index' }).catch(() => {});
            window.location.reload();
         } catch (error) {
            ErrorHandler.handle(error);
         } finally {
            this.isTeamChanging = false;
         }
      },
      async changeTeamToNew(response) {
         this.changeTeam(response.data);
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-header {
   display: grid;
   padding: 30px;
   grid-gap: 42px;
   background-color: var(--primary-color) !important;

   .obi-team-button {
      background: #1d4da5 !important;
      border-color: #1d4da5 !important;

      &.first {
         border-right: 2px solid #225ac0 !important;
      }
   }

   .obi-header--top {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
   }

   .obi-header--account-link {
      gap: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
   }

   .shake {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      z-index: 999;
   }

   @keyframes shake {
      10%,
      90% {
         transform: translate3d(-1px, 0, 0);
      }
      20%,
      80% {
         transform: translate3d(2px, 0, 0);
      }
      30%,
      50%,
      70% {
         transform: translate3d(-4px, 0, 0);
      }
      40%,
      60% {
         transform: translate3d(4px, 0, 0);
      }
   }
}
</style>
